import React, { useState } from "react";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Controller } from "react-hook-form";
import { RcFile } from "antd/es/upload";

const UploadDoc = ({
  name,
  control,
  rules,
}: {
  reset: any;
  name: string;
  setValue: any;
  register: any;
  control: any;
  rules?: any;
}) => {
  const uploadZip = (file: RcFile, FileList: RcFile[]) => {
    return false;
  };

  const deleteZip = () => {
    return true;
  };

  return (
    <div>
      <Controller
        control={control}
        name={name}
        rules={rules ? rules : ""}
        render={({ field }) => (
          <Upload
            beforeUpload={uploadZip}
            accept={".zip,.pdf"}
            maxCount={1}
            onChange={(e) => {
              if (e.fileList.length) {
                field.onChange(e.file);
              } else {
                field.onChange();
              }
            }}
            onRemove={(e) => {
              deleteZip();
            }}
          >
            <Button icon={<UploadOutlined />}>Загрузить</Button>
          </Upload>
        )}
      />
    </div>
  );
};

export default UploadDoc;
