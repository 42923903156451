import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadingStatus } from "./appReducer";
import { bookAPI, profileAPI } from "../api/api";
import { TextBookService } from "../services/TextBookService";
import { openSuccessNotification } from "../utils/Notification";

export interface InitialStateType {
  books: Array<any> | undefined;
  countBooks: number | null;
  filterDisciplines: Array<{ id: number; name: string; icon_url: string }> | null;
  filterClasses: Array<{ id: number; name: string }> | null;
}

const initialState: InitialStateType = {
  books: undefined,
  countBooks: null,
  filterDisciplines: null,
  filterClasses: null,
};

export const textBooksReducer = createSlice({
  name: "textbooks",
  initialState,
  reducers: {
    setTextBooks: (state, action: PayloadAction<Array<any>>) => {
      state.books = action.payload;
    },

    setCountTextBooks: (state, action: PayloadAction<number>) => {
      state.countBooks = action.payload;
    },
    setFilterDisciplines: (state, action: PayloadAction<Array<{ id: number; name: string; icon_url: string }>>) => {
      state.filterDisciplines = action.payload;
    },
    setFilterClasses: (state, action: PayloadAction<Array<{ id: number; name: string }>>) => {
      state.filterClasses = action.payload;
    },
  },
});

export const { setTextBooks, setCountTextBooks, setFilterDisciplines, setFilterClasses } = textBooksReducer.actions;

export const getAllBooks =
  ({
    offset = 0,
    discipline_id,
    limit = 10,
    class_id,
  }: {
    offset: number;
    discipline_id?: number;
    class_id?: number;
    limit?: number;
  }) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await TextBookService.getAllBook({
        offset,
        limit,
        ...(discipline_id && { discipline_id: discipline_id }),
        ...(class_id && { class_id: class_id }),
      });

      let data = response.data.books.map((e: any) => ({
        key: e.id,
        id: e.id,
        name: e.name,
        author: e.author,
        class_id: e.class.id,
        discipline_id: e.discipline.name,
      }));

      dispatch(setCountTextBooks(response.data.amount_of_books));
      dispatch(setTextBooks(data));
    } catch (err: any) {
      if (err.response) {
        console.log(err.response);
      }
      console.log(err);
    }

    dispatch(loadingStatus(false));
  };

export const removeBook = (id: number, currentPage: number) => async (dispatch: any) => {
  dispatch(loadingStatus(true));
  try {
    const { data } = await TextBookService.removeBook(id);
    dispatch(getAllBooks({ offset: currentPage - 1 }));
    console.log(data);
    openSuccessNotification("Успешное удаление");
  } catch (e) {
    console.log(e);
  }
  dispatch(loadingStatus(false));
};

export const getFilterDisciplines = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));
  try {
    const { data } = await bookAPI.getFilterDisciplines();
    const classes = await bookAPI.getFilterClass();
    dispatch(setFilterDisciplines(data));
    dispatch(setFilterClasses(classes.data));
    console.log(data);
  } catch (e) {
    console.log(e);
  }
  dispatch(loadingStatus(false));
};

export default textBooksReducer.reducer;
